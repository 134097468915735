// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "w_dd";
export var qaBenefitsSection = "w_c3";
export var qaBusinessSection = "w_c4";
export var qaCtaSection = "w_c9";
export var qaCtaSection__cta = "w_db";
export var qaDomainExpertiseSection = "w_c7";
export var qaResultSection = "w_c6";
export var qaServicesSection = "w_c0";
export var qaTechStackSection = "w_c8";
export var qaTestimonialPost = "w_c5";
export var qaTestingServicesSection = "w_c1";
export var qaVideoClientSection = "w_df";
export var qaWhenDoYouNeedSection = "w_c2";
export var qaWhyChooseSection = "w_dc";